import { Input, Typography, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, ChangeEvent } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Practitioner } from 'api/practitionerApi';
import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { IDENTITIES } from 'constants/practitioner';
import RootStoreContext from 'context/RootStoreContext';
import { sortWithLocale } from 'utils/textUtils';

import styles from './PractitionersList.module.css';

interface Props extends RouteComponentProps, WrappedComponentProps {}
@observer
class PractitionersList extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable private searchQuery = '';

  private breadcrumbs = [
    {
      iconName: 'team',
      text: <FormattedMessage id="main-navigation.practitioners" />,
    },
  ];

  componentDidMount() {
    this.context.practitionersStore.fetchPractitioners();
  }

  get columns() {
    const { userDataStore: userStore } = this.context;
    const columns = [
      {
        title: <FormattedMessage id="practitioners-list.practitioner-name" />,
        dataIndex: 'givenName',
        render: (_: string, practitioner: Practitioner) => (
          <Link data-testid={practitioner.id} to={`/roles/${practitioner.id}/edit`}>
            {`${practitioner.givenName} ${practitioner.middleAndSurname}`}
          </Link>
        ),
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Practitioner, b: Practitioner) =>
          sortWithLocale(
            `${a.givenName} ${a.middleAndSurname}`,
            `${b.givenName} ${b.middleAndSurname}`
          ),
        width: '30%',
      },
      {
        title: <FormattedMessage id="practitioners-list.practitioner-id" />,
        dataIndex: 'externalId',
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Practitioner, b: Practitioner) => sortWithLocale(a, b, 'externalId'),
        render: (id: string, practitioner: Practitioner) =>
          practitioner.externalIdType === IDENTITIES.HSA_ID
            ? id
            : practitioner.externalIdType === IDENTITIES.INTERNAL_IDP
              ? practitioner.email
              : null,
      },
      {
        title: <FormattedMessage id="practitioners-list.practitioner-email" />,
        dataIndex: 'email',
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Practitioner, b: Practitioner) => sortWithLocale(a, b, 'email'),
        width: '30%',
      },
      {
        title: <FormattedMessage id="practitioners-list.practitioner-title" />,
        dataIndex: 'title',
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Practitioner, b: Practitioner) => sortWithLocale(a, b, 'title'),
        width: '15%',
      },
    ];

    // No ID column for Norwegian users, as they won't see any id either way
    if (userStore.isNorwegianUser) {
      columns.splice(1, 1);
    }

    return columns;
  }

  @computed
  get practitioners() {
    if (!this.searchQuery) {
      return [];
    }

    const practitioners = this.context.practitionersStore.list.map(practitioner => ({
      ...practitioner,
      name: `${practitioner.givenName} ${practitioner.middleAndSurname}`,
    }));

    const searchQuery = this.searchQuery.toLowerCase();
    return practitioners.filter(
      ({ name, externalId, email }) =>
        name.toLowerCase().includes(searchQuery) ||
        externalId.toLowerCase().includes(searchQuery) ||
        email?.toLowerCase().includes(searchQuery)
    );
  }

  @action
  private handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchQuery = event.target.value;
  };

  private handleAddButtonClick = () => {
    this.props.history.push('/roles/add');
  };

  render() {
    const { practitionersStore } = this.context;
    const { intl } = this.props;

    return (
      <div className={styles.container}>
        <PageHeader
          content={
            <Typography.Title level={2}>
              <FormattedMessage id="main-navigation.practitioners" />
            </Typography.Title>
          }
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <Input.Search
              placeholder={intl.formatMessage({
                id: 'practitioners-list.search-placeholder',
              })}
              data-testid="practitioner-search-input"
              onChange={this.handleSearchChange}
              disabled={practitionersStore.isLoading()}
              className={styles.search}
            />
          }
        />

        <Table
          columns={this.columns}
          loading={practitionersStore.isLoading()}
          dataSource={this.practitioners}
          className={styles.table}
          rowKey="id"
        />
        <PlusFloatingButton onClick={this.handleAddButtonClick} />
      </div>
    );
  }
}

export default injectIntl(PractitionersList);
